<div>
  <div class="row">
    <div class="container-fluid">
      <span>
        <app-table-template [columns]="columns1" [tableData]="orderDetails | async"
          (deleteBtnClickEvent)="deleteCart($event)"></app-table-template>
      </span>
    </div>
  </div>
</div>

<div *ngIf="updatePoup">
  <div class="modal fade" tabindex="-1" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">
            {{ "orderDetail.cancelOrder" | cxTranslate }}
          </h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ "orderDetail.cancelOrderEntryMessage" | cxTranslate }}</p>
          <div>
            <textarea class="comment" placeholder="You comment should be in with in 250 characters" rows="3" cols="60"
              maxlength="250" [(ngModel)]="comment">
            </textarea>
          </div>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="button" class="btn cstm-btn-light" data-dismiss="modal" style="float: left">
            <a>{{ "orderDetail.cancel" | cxTranslate }}</a>
          </button>
          <button type="button" class="btn cstm-btn-primary" data-dismiss="modal" style="float: left"
            (click)="cancelOrder()">
            <a> {{ "orderDetail.confirm" | cxTranslate }}</a>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>